<template>
  <common fixed-body>
    <div class="search-layout">
      <main-header as-search-header :fixed-open-main-header="false" />
      <slot></slot>
    </div>
  </common>
</template>

<script lang="ts">
import { useHead } from '#head'
import MainHeader from '~/components/layout/MainHeader.vue'
import { useViewTools } from '~/composables'
import Common from '~/layouts/common.vue'

export default {
  name: 'search',
  components: { Common, MainHeader },
  setup() {
    useHead({
      bodyAttrs: {
        class: 'map-visible',
      },
    })
    return {
      ...useViewTools(),
    }
  },
}
</script>

<style scoped lang="scss">
.search-layout {
  display: flex;
  flex-direction: column;
}
</style>
